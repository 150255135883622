import { CustomError, capitalize, getErrorMessage, getStandardErrorBy, serverText, slugify } from "functions";
import { Specialization, WOW_API_GetCharacter, WOW_API_GetFactionAuctions, WOW_API_GetGuildRoster, WoWAuctionsResponse, iCharacterFull, iClass, iFetchParams, iLocationClassSpec, iSpec, iWoWCharSpecialization, iWoWCharSummary, iWoWGuildRoster, isArrayWithLength, serverRegion, wowNamespaces } from "typings";

const generateWowNamespaces = (suffix: string): wowNamespaces => ({
  static: (region) => `static${suffix}-${region}`,
  dynamic: (region) => `dynamic${suffix}-${region}`,
  profile: (region) => `profile${suffix}-${region}`,
});

export const namespaces_WOW_ERA_SOD = generateWowNamespaces('-classic1x');
export const namespaces_WOW_CLASSIC_PROG_1 = generateWowNamespaces('-classic');
export const namespaces_WOW_RETAIL = generateWowNamespaces('');


type charMode = "summary" | "specializations" | "equipment"  | "character-media"
type guildMode = "summary" | "achievements" | "activity"  | "roster"
type battlenetReturnType<T extends charMode> = 
  T extends "summary" ? iWoWCharSummary :
  T extends "specializations" ? iWoWCharSpecialization :
  never

export const getBattleNetEndpoints = (server: string, region: serverRegion) => {
  const serverString = slugify(serverText(server, false))
  const baseURL = `https://${region}.api.blizzard.com`

  const getModeString = (modeInput: charMode | guildMode) => {
    if(modeInput === "summary") return ""
    return "/" + modeInput
  }
  
  const getCharURLs = (charName: string) => {

    const createCharModeEndpoint = (modeInput: charMode) => () => {
      const specificURL = `${serverString}/${charName.toLowerCase()}${getModeString(modeInput)}`
      return { url: `${baseURL}/profile/wow/character/${specificURL}`, fileName: specificURL.replaceAll("/", "-") }
    };

    return {
      summary: createCharModeEndpoint("summary")(),
      specialization: createCharModeEndpoint("specializations")(),
      equipment: createCharModeEndpoint("equipment")(),
      media: createCharModeEndpoint("character-media")(),
    }
  }

  const getGuildURLs = (guildName: string) => {

    const createGuildModeEndpoint = (modeInput: guildMode) => () => {
      const specificURL = `${serverString}/${slugify(guildName)}${getModeString(modeInput)}`
      return { url: `${baseURL}/data/wow/guild/${specificURL}`, fileName: specificURL.replaceAll("/", "-") }
    };

    return {
      summary: createGuildModeEndpoint("summary")(),
      activity: createGuildModeEndpoint("activity")(),
      roster: createGuildModeEndpoint("roster")(),
      achievements: createGuildModeEndpoint("achievements")(),
    }
  }

  const getItemURLs = (itemID: string) => {
    return {
      summary: { url: `${baseURL}/data/wow/item/${itemID}`, fileName: `items/${itemID}` },
    }
  }

  const getAuctionHouseURLs = (connectedRealmId: number, auctionHouseId: number) => {
    // NOTE: Only works for classic AHs. Retail AH returns different data.
    return {
      auctions: {
        url: `${baseURL}/data/wow/connected-realm/${connectedRealmId}/auctions/${auctionHouseId}`,
        fileName: `auctions/${connectedRealmId}-${auctionHouseId}`
      }
    }
  }

  return {
    char: getCharURLs,
    guild: getGuildURLs,
    item: getItemURLs,
    auctionHouse: getAuctionHouseURLs
  };
}

export const wowAPI = (nameSpaces: wowNamespaces) => {

  const getParams = async ({
    getToken,
    region
  }: {
    region: serverRegion
    getToken: () => Promise<string>
  }): Promise<iFetchParams> => {
    const _namespace: string = nameSpaces.profile(region)
    const tokenString = await getToken()
    return {
      params: {
        namespace: _namespace,
        locale: "en_US"
      },
      headers: {
        Authorization: `Bearer ${tokenString}`
      }
    }
  }

  const getCharacter: WOW_API_GetCharacter = async (parameters) => {
    const { charName, getToken, region, server, location, OAuthCall } = parameters

    const fetchParams = await getParams({ getToken, region })
    const { summary, specialization } = getBattleNetEndpoints(server, region).char(charName)

    const [summaryData, specData] = await Promise.all([
      OAuthCall({ url: summary.url, fetchParams, fileName: summary.fileName }),
      OAuthCall({ url: specialization.url, fetchParams, fileName: specialization.fileName }),
    ])

    if(summaryData?.code || specData?.code) {
      const standardError = new CustomError(getStandardErrorBy({ status: summaryData?.code }))
      const feedbackString = `${region.toUpperCase()} - ${capitalize(server)} - ${capitalize(charName)}:\n${getErrorMessage(
        standardError
      )}`
      return { status: "error", feedback: { mode: "custom", msg: feedbackString }}
    }

    const char = wowAPICharSummaryToiCharacterFull(
      summaryData,
      specData,
      server,
      location
    )
    return { status: "success", data: char }
  }

  const getGuildRoster: WOW_API_GetGuildRoster = async (params) => {
    const { guildName, getToken, region, server, OAuthCall } = params
    try {
      const fetchParams = await getParams({ getToken, region })
      const roster = getBattleNetEndpoints(server, region).guild(guildName).roster

      const [guildRoster] = await Promise.all([
        OAuthCall({ url: roster.url, fetchParams, fileName: roster.fileName }),
      ])
      return { status: "success", data: guildRoster as iWoWGuildRoster }
    } catch (error) {
      console.error(error)
      return { status: "error", feedback: { mode: "custom", msg: "Failed to fetch guild roster" } }
    }
  }

  const getFactionAuctions: WOW_API_GetFactionAuctions = async (params) => {
    const { getToken, region, server, serverID, auctionHouseID, OAuthCall } = params
    try {
      const fetchParams = await getParams({ getToken, region })
      const factionAuctions = getBattleNetEndpoints(server, region).auctionHouse(serverID, auctionHouseID).auctions

      const [auctions] = await Promise.all([
        OAuthCall({ url: factionAuctions.url, fetchParams, fileName: factionAuctions.fileName }),
      ])
      return { status: "success", data: auctions as WoWAuctionsResponse }
    } catch (error) {
      console.error(error)
      return { status: "error", feedback: { mode: "custom", msg: "Failed to fetch faction auctions" } }
    }
  }

  return {
    getCharacter,
    getGuildRoster,
    getFactionAuctions
  }
}

type specInfo = {
  label: string;
  talentSpread: Record<string, number>;
  isActive: boolean;
}

export function getSpecializationInfo(specialization: iWoWCharSpecialization) {
  const returnObj: Record<string, specInfo> = {}

  for (const specGroup of specialization?.specialization_groups || []) {
    let maxSpentPoints = 0
    let maxSpentPointsSpec: Specialization | null = null
    if(!specGroup?.specializations) continue

    for (const spec of specGroup.specializations) {
      if (spec.spent_points > maxSpentPoints) {
        maxSpentPoints = spec.spent_points
        maxSpentPointsSpec = spec
      }
    }
    if (!maxSpentPointsSpec) continue
    if(maxSpentPointsSpec?.specialization_name.toLowerCase() === "feral combat"){
      maxSpentPointsSpec.specialization_name = "feral"
    }

    returnObj[maxSpentPointsSpec?.specialization_name] = {
      label: maxSpentPointsSpec?.specialization_name,
      talentSpread: specGroup.specializations.reduce((acc, item) => {
        return { ...acc, [item.specialization_name]: item.spent_points }
      }, {} as Record<string, number>),
      isActive: specGroup.is_active
    }
  }

  return returnObj
}

const getMainAltSpecs = (charSpecialization: iWoWCharSpecialization, locClass: iClass): { mainSpec: iSpec, altSpecs: iSpec[]} => {

  const results = getSpecializationInfo(charSpecialization)
  const specInfoArray = Object.values(results)
    .filter((specInfo) => !!locClass.specs?.[specInfo?.label?.toLowerCase()])
  
  // couldn't find any specs
  if(!isArrayWithLength(specInfoArray)){
    return {
      mainSpec: Object.values(locClass.specs)[0],
      altSpecs: []
    }
  }

  const mainSpecIndex = specInfoArray.findIndex(specInfo => specInfo.isActive === true)
  const specs = specInfoArray.map((specInfo) => locClass.specs?.[specInfo?.label?.toLowerCase()])
  const mainSpec = specs.splice(mainSpecIndex, 1)[0]
  if(!mainSpec) throw new Error("No spec found for ") 

  return {
    mainSpec,
    altSpecs: specs
  }
}


export const wowAPICharSummaryToiCharacterFull = (
  charSummary: iWoWCharSummary,
  charSpecialization: iWoWCharSpecialization,
  server: string,
  location: iLocationClassSpec
) => {
  const classString = charSummary.character_class.name.toLowerCase()
  const locClass = location.game.classes[classString]
  if(!locClass) throw new Error(`Couldn't find class for ${classString}`)

  const { altSpecs, mainSpec } = getMainAltSpecs(charSpecialization, locClass)

  const char: iCharacterFull = {
    charName: charSummary.name.toLowerCase(),
    charClass: classString,
    charSpec: mainSpec.gameSpec,
    gameRole: mainSpec.gameRole,
    faction: charSummary.faction.name.toLowerCase(),
    altSpecs: altSpecs.map(spec => spec.gameSpec),
    location: location.locMap,
    server
  }

  return char
}